import React, { useCallback, useEffect, useState } from "react";
import { FC, useMemo } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import {
    getTabSelectionFromUrl,
    ProductGroup,
    ProductId,
    Section,
    SectionDropdown,
    SectionId,
    setSelectedTab,
    setSubNavSelectedTab,
    setUpsellModal,
    showActionButton,
    useNavState,
    warnOrThrow,
} from "../../../common";
import { MainMenu, MainMenuProps } from "../../shared/main-menu";
import { NavigationEvent, UUISubject, UUISubjectPayloads } from "../../subjects";
import { useAuthNavConfigContext } from "../nav-config";

export const AuthMainMenu: FC = () => {
    const {
        nav: {
            tabSelection: activeProductId, // the id for the product the user is currently viewing. This reflects the user's navigation state.
            emitDetailedClickEvent,
        },
        dispatch,
    } = useNavState();
    const { loading, navConfig } = useAuthNavConfigContext();

    /* Records the currently "selected" tab. This is different from the tab the
     * For example, say a user is currently viewing the Sales map view. They have
     * a subscription to Sales but not Properties. When they click on the Properties
     * tab, "PROPERTIES" will be stored here, but because they don't have a subscription,
     * they wont be navigated and so the "tab selection" in app state won't be updated.
     */

    const dispatchNewSelection = useCallback(
        (tab: ProductId) => {
            dispatch(setSelectedTab(tab));

            const tabSection = navConfig.find(t => t.id === tab);
            if (tabSection && tabSection.sections.length > 0) {
                dispatch(showActionButton("back", false)); // when clicking the main menu nav buttons we hide the 'back' button because some consumers forget to set as false - leading to bugs and uui thinking we are inside a detail page.
                if (
                    !tabSection.sections[0].isUserSubscriber &&
                    tabSection.sections[1].isUserSubscriber &&
                    !tabSection.sections[1].dropdownItems
                ) {
                    dispatch(setSubNavSelectedTab(tabSection.sections[1].id));
                } else {
                    dispatch(setSubNavSelectedTab(tabSection.sections[0].id));
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeProductId, dispatch, navConfig, activeProductId]
    );

    const navigateToNewProduct = useCallback(
        (product: ProductGroup | Section | SectionDropdown) => {
            const { id, baseUrl, isUserSubscriber, newTab } = product;

            const tabHref = product.href || (product as ProductGroup)?.defaultHref;
            const href = tabHref && new URL(tabHref, window.location.origin).toString();

            // This function shouldn't be called when the user isn't a subscriber.
            // This invariant guards against potential future bugs.
            if (!isUserSubscriber) {
                dispatch(setUpsellModal(isUserSubscriber, id));
                throw new Error("Cannot navigate to a product the user is not subscribed to. This is a bug.");
            }

            // When consumer wants the UUI to handle navigation, emit event
            // anyways if we don't know where to navigate to
            const shouldEmitNavigationEvent: boolean = emitDetailedClickEvent || href === undefined;
            if (shouldEmitNavigationEvent) {
                const navEventPayload: NavigationEvent = {
                    id,
                    href,
                    selfHandleRoute: false, // this seems to always be false in <costar-nav-item />
                    baseUrl: baseUrl,
                    isUserSubscribed: true,
                    landingPath: undefined, // what is this?
                    // This business logic is copied from uui <costar-nav-item />
                    processClickEvent(highlightOnly = false) {
                        if (highlightOnly) {
                            if (id.includes("LEASE")) {
                                dispatchNewSelection("LEASING" as ProductId);
                            } else if (id === "FOR_SALE") {
                                dispatchNewSelection("SALES" as ProductId);
                            } else {
                                const tab = navConfig.find(({ id }) => id === this.id);
                                if (tab) dispatchNewSelection(tab.id);
                            }
                        } else if (href) {
                            if (newTab) {
                                window.open(href, "_blank");
                            } else {
                                window.location.href = href;
                            }
                            // todo: push to history stack so that back works
                        }
                    },
                };

                // Dispatch event to pub/sub bus
                subject<UUISubjectPayloads[UUISubject.MenuTabSelected]>(UUISubject.MenuTabSelected).notify(
                    navEventPayload
                );
            } else {
                const tab = navConfig.find(({ id: tabId }) => tabId === id);
                tab && dispatchNewSelection(tab.id);
                window.location.href = href as string;
            }
        },
        [dispatch, dispatchNewSelection, emitDetailedClickEvent, navConfig]
    );

    const setSelection = useCallback(
        (tabSelected: ProductId, hoverItemSelected?: SectionId) => {
            let tab: ProductGroup | Section | undefined;
            tab = navConfig.find(({ id }) => id === tabSelected);

            // find the hoverItem, if it was provided
            if (tab && hoverItemSelected) {
                tab = tab.hoverItems?.find(({ id }) => id === hoverItemSelected);
            }

            if (!tab) {
                // warn if we couldnt find either product.
                const selectedTab = hoverItemSelected ? hoverItemSelected : tabSelected;
                tab = warnOrThrow(`Could not find tab with id ${selectedTab}`, navConfig[0]);
            }
            /* When subscribed, publish a nav event to the bus. If Costar Suite decides
             * to continue with navigation, update the MFE's internal state by dispatching
             * the corresponding action to the state reducer.
             *
             * If they're not subscribed, we still need to propagate down the "selected"
             * tab, which has not been navigated to, down to the main nav so that it
             * can show an upsell message.
             */
            const { id, isUserSubscriber } = tab;
            let found = false;
            if (isUserSubscriber) {
                if (id === "LEASING") {
                    // if for lease subscriber
                    if (tab.sections[0].isUserSubscriber) {
                        navigateToNewProduct(tab);
                    } // if lease comps subscriber
                    else if (tab.sections[1].isUserSubscriber) {
                        if (tab.sections[1].dropdownItems) {
                            for (const dropItem of tab.sections[1].dropdownItems) {
                                if (dropItem.isUserSubscriber) {
                                    navigateToNewProduct(dropItem);
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                navigateToNewProduct(tab.sections[1]);
                            }
                        } else {
                            navigateToNewProduct(tab.sections[1]);
                        }
                    } // if lease analysis subscriber
                    else if (tab.sections[2].isUserSubscriber) {
                        if (tab.sections[2].dropdownItems) {
                            for (const dropItem of tab.sections[2].dropdownItems) {
                                if (dropItem.isUserSubscriber) {
                                    navigateToNewProduct(dropItem);
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                navigateToNewProduct(tab.sections[2]);
                            }
                        }
                    }
                } else if (id === "SALES" && !tab.sections[0].isUserSubscriber && tab.sections[1].isUserSubscriber) {
                    navigateToNewProduct(tab.sections[1]);
                } else {
                    navigateToNewProduct(tab);
                }
            } else {
                dispatch(setUpsellModal(isUserSubscriber, id));
            }
        },
        [dispatch, navConfig, navigateToNewProduct]
    );

    const menuProps: MainMenuProps = useMemo<MainMenuProps>(
        () => ({
            selection: activeProductId,
            navConfig,
            setSelection: loading ? stubSetSelection : setSelection,
            loading,
        }),
        [setSelection, navConfig, activeProductId, loading]
    );

    useEffect(() => {
        window.addEventListener("popstate", function (event) {
            const { mainTab, subTab } = getTabSelectionFromUrl(window.location.pathname);

            mainTab && dispatch(setSelectedTab(mainTab));
            subTab && dispatch(setSubNavSelectedTab(subTab));
        });
    }, [dispatch, activeProductId]);

    return <MainMenu {...menuProps} />;
};

function stubSetSelection() {
    // noop
}
