import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import { GEOCODE_DETAIL_QUERY, GeocodeDetailQuery, GeocodeDetailQueryVariables, SearchType } from "$graphql/core";

export async function getGeocodeDetail(
    client: ApolloClient<NormalizedCacheObject>,
    PlaceId: string,
    searchType: SearchType
): Promise<string | undefined> {
    const queryResponse = await client.query<GeocodeDetailQuery, GeocodeDetailQueryVariables>({
        query: GEOCODE_DETAIL_QUERY,
        variables: {
            PlaceId,
            searchType,
        },
    });
    return queryResponse?.data?.search?.geocodeDetail as string | undefined;
}
