import * as React from "react";
import { FC } from "react";

import type { ButtonProps } from "@costar/theme-ui";
import { Box, Button } from "@costar/theme-ui";

export const HamburgerIcon: FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <Button data-testid="hamburger-icon" ref={ref} variant="icon" paddingLeft="lg" py="0" height="48px" {...props}>
            <Box height="8px" width="24px">
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 12V10H18V12H0ZM0 7V5H18V7H0ZM0 2V0H18V2H0Z" fill="#757575" />
                </svg>
            </Box>
        </Button>
    );
});

if (process.env.NODE_ENV !== "production") {
    HamburgerIcon.displayName ||= "HamburgerIcon";
}
