import { HTMLAttributes } from "react";

import { Culture as AcceptedLanguage } from "@costar/i18n-types";
// use common source of truth
export type { AcceptedLanguage };

export type Nullish<T> = T | null | undefined;
export type Type<T, P extends any[] = any[]> = {
    new (...args: P): T;
};

export type ParsedUserPreferences = Partial<
    Readonly<{
        CultureName: AcceptedLanguage | string;
        CurrencyName: string;
        AreaUnitOfMeasure: string;
        LengthUnitOfMeasure: string;
        FeatureToggleMetadata: string;
    }>
>;

export enum FeatureToggleCriteria {
    CSFEATURES = "cs-features-",
}

export enum FeatureToggleHeaders {
    LISTINGS = "listings",
    PROPERTIES = "modern-properties",
    MARKETSMEF = "markets-mfe",
    GEOCODETYPEAHEAD = "geocode-lookup",
}
declare global {
    namespace JSX {
        interface IntrinsicElements {
            "costar-uui-header": CostarUUIHeaderAttributes;
        }

        interface CostarUUIHeaderAttributes extends HTMLAttributes<any> {
            "app-root": string;
        }
    }

    namespace Express {
        interface Request {
            userPreferences?: ParsedUserPreferences | undefined;
        }
    }
}
